@import "_variables.scss";

p {
  border-bottom: 1px solid #888;
}

.bold {
  font-family: font_b;
}

.light {
  font-family: font_l;
  font-weight: normal;
}

a {
  color: white;
  text-decoration: none;
  position: relative;
  transition: all 0.2s ease-in-out;
}

.hover_animation {
  &::after {
    content: " ";
    position: absolute;
    width: 100%;
    background-color: white;
    height: 3px;
    border-radius: 16px;
    bottom: -2px;
    left: 0;
    transform: scaleX(0);
    transition: all 0.2s ease-in-out;
  }
  &:hover {
    &::after {
      visibility: visible;
      transform: scale(1);
    }
  }
}

.rounded_border_bottom {
  position: relative;
  &:after {
    position: absolute;
    height: 2px;
    content: " ";
    bottom: 0;
    left: 0;
    border-radius: 30px;
    width: 100%;
    background-color: #e2e8f0;
  }
}

.rounded_border_left {
  padding-left: 25px;
  &:before {
    height: 100%;
    width: 8px;
    position: absolute;
    content: " ";
    bottom: 0;
    left: -2px;
    border-radius: 30px;
    background-color: rgb(233, 233, 233);
  }
}

.rounded_border_left_xl {
  &:after {
    width: 8px;
  }
}

::-webkit-scrollbar {
  width: 10px;
  border-radius: 30px;
}

::-webkit-scrollbar-track {
  border-radius: 30px;
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  border-radius: 30px;
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.icon-spin {
  -webkit-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
}

@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

p {
  line-height: normal !important;
}

.nav {
  font-family: "font_l";
}
.nav_active {
  font-family: "font_b" !important;
}

/*
Used in ModalCodesPhotos
do not remove
*/

@import "variables";
.thumb {
  width: 90px;
  border-radius: 10px;
  text-align: center;
  .image {
    border-radius: 10px;
    width: 90px;
    height: 90px;
    background-size: cover;
    background-position: center;
    color: $blue;
    background-color: #eee;
    position: relative;
    svg {
      position: absolute;
      right: 10px;
      padding: 5px;
      box-sizing: content-box;
      bottom: 10px;
      background-color: white;

      border-radius: 100px;
    }
  }
  p {
    font-size: 12px;
    padding: 5px;
    color: rgb(197, 197, 197);
    width: 100%;
  }
}

.thumb.qr {
  background-color: lighten($blue, 30);
  p {
    color: $blue;
  }
}

.thumb_padding {
  padding-right: 5px;
}

.thumb_dragover {
  border-right: 3px solid $blue;
  padding-right: 2px;
}

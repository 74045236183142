@media print {
    @page {
        margin: 0;
        size: auto;
    }
}

.page_a4 {
    width: 21cm;
    height: 29.7cm;
    background-color: pink;
}

.text-vertical {
    // text-orientation: mixed;
    h1,
    p {
        writing-mode: vertical-rl;
        transform: rotate(180deg);
    }
}
.rotate-90 {
    transform: rotate(90deg);
}

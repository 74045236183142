@import '_variables';

.options {
    .options_toggler {
        display: flex;
        gap: 10px;
        position: relative;
        cursor: pointer;
    }
}

.dropzone {
    width: 100%;
    border: 2px solid rgb(238, 238, 238);
    border-radius: 20px;
    padding: 46px 30px;
    text-align: center;
    transition: 0.2s all;
}
.dropzone_dragging {
    border-color: lighten($blue, 10) !important;
}

